:root {
    --shadow: 0 0.1rem 1.5rem 0 rgb(22 39 86 / 10%);
    --shadow-0: 0 0 10px rgba(0, 0, 0, 0.2);
    --shadow-1: 0 0.1rem 0.9rem 0 rgb(22 39 86 / 20%);
    --shadow-2: rgb(0 17 51 / 7%) 0px 3px 15px;
    --shadow-3: 0 1.2rem -1.5rem 0.8rem rgba(22, 39, 86, 0.459);
    --shadow-5: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    --shadow-x: 0 8px 32px 0 rgba(8, 10, 43, 0.12);
    --radius: 5px;
    --radius-2: 10px;
    --light: #fff;
    --light-2: #f4f4f4;
    --orange: #f25c4f;
    --blue: #5e7bc9;
    --light-blue-1: #146b94de;
    --gray: rgba(84, 84, 84, 0.8) !important;
    --gray-2: #545454 !important;
    --gray-3: #5454549f !important;
    --light-orange: #e7695dd0;
    --light-orange-1: #e7695d75;
    --light-blue: rgba(94, 123, 201, 0.7);
    --light-blue-0: #146b9442;
    --red: #fd384f;
    --clr-primary: #009ef7;
    --clr-success: #50cd89;
    --clr-warning: #ffc700;
    --clr-danger: #f1416c;
    --clr-light-primary: #f1faff;
    --clr-light-success: #e8fff3;
    --clr-light-warning: #fff8dd;
    --clr-light-danger: #fff5f8;
    --dark: #272B3A;
    --light-dark: #272b3a2a;
    --light-dark-2: #272b3a11;
    --radius: 5px;
    --border-dashed: 1px var(--light-blue-0) dashed !important;
    --border-dashed-1: 1px var(--light-blue-0) dashed !important;
    --border-solid: 1px var(--light-dark-0) solid !important;
    --category-img-lg: 380px;
    --category-img-md: 335px;
    --transition: all .7s !important;
}

.bh-logo {
    /* height: 100% !important; */
    /* width: 13rem !important; */
    height: 8rem !important;
    margin: -40px 0 0 0 !important;
    /* border-radius: 50% !important; */
    /* box-shadow: rgb(0 17 51 / 7%) 0px 3px 15px !important; */
}

.of-scale {
    object-fit: scale-down !important;
}

.bh-logo-container {
    /* margin: -45px 0px -35px -15px !important; */
    /* margin: -47px 0px -35px -15px !important; */
    z-index: 0 !important;
    height: 6rem !important;
}

.bh-mt-6 {
    margin: 3rem 0px 0px 0px !important;
}

.bh-m-row {
    margin: -15px 0 -13px 0 !important;
}

.bh-col-12 {
    flex: 0 0 auto;
    width: 100%;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.bh-table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.bh-justify-content-end {
    justify-content: flex-end !important;
}

.bh-justify-content-start {
    justify-content: flex-start !important;
}

.bh-justify-content-center {
    justify-content: center !important;
}

.bh-d-none {
    display: none !important;
}

.bh-d-flex {
    display: flex !important;
}


.bh-mb-3 {
    margin-bottom: 0.75rem !important;
}

.bh-fw-bold {
    font-weight: 600 !important;
}

.bh-user-info-client {
    font-size: 22px !important;
    margin: -3px 0 1rem 0 !important;
}

/* .p-w {
    width: 290px !important;
} */

.bh-info-text {
    font-size: 14px !important;
}

.bh-info-value {
    font-size: 14px !important;
}

.bh-container {
    max-width: 1100px !important;
    min-height: 100vh;
    position: relative;
}

.cardfooter {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;

}

.hrtag {
    opacity: .05 !important;
    margin: 0.2rem 0px 1rem 0px !important;
    z-index: 999 !important;
}

.bh-tb-radius {
    border-radius: 10px !important
}

.bh-p-10 {
    padding: 0px 0px 5px 0px !important
}

.bh-p-1 {
    padding: 0px 0px 1px 0px !important
}

.bh-p-2 {
    padding: 0px 0px 2px 0px !important
}

.m-table {
    margin: 10px 0 0 0 !important;
}

.bh-m-client {
    margin: 0px 0px 10px 0px !important;
}

.bh-price-value {
    font-size: 17px !important;
}

.bh-price-text {
    font-size: 18px !important;
}

.bh-total-price {
    font-size: 19px !important;
}

.bh-total-price-p {
    margin: 5px 0 0 0 !important;
}

.fs-7 {
    font-size: 11px !important;
    font-weight: 600 !important;
}

.bh-line {
    line-height: 25px !important;
}

.bh-t-center {
    text-align: center !important;
}

.bh-t-end {
    text-align: right !important;
    padding: 0px 7px 0px 0px !important;
}

.bh-txt-end {
    text-align: right !important;
    padding-right: 10px !important;
}

.col-width {
    width: 37rem !important;
}

.col-carte-width {
    width: 40rem !important;
}

.total-min-w {
    min-width: 5rem !important;
}

.bh-t-dark {
    color: #000 !important;
}

.ml-13 {
    margin-left: 13px !important;
}

/* .mt-6 */

.bh-p-0 {
    /* padding: 11px 0px 4px 0px !important */
    padding: 5px 0px 0px 0px !important;
}

.bh-p-0-1 {
    padding: 0px 1px 0px 0px !important;
}

.row-user {
    margin: 0 0 -10px 0 !important;
}

p {
    margin-bottom: 0px !important;
}

.fs-5 {
    font-size: 1.15rem !important;
}

.fs-6 {
    font-size: 1.075rem !important;
}

.bh-data {
    font-size: 12px !important;
}

.separate {
    padding: 0px 10px !important;
}

.bh-mb-10 {
    margin: 0 0 10px 0 !important;
}

.card .card-footer {
    padding: 2rem 2.25rem;
    color: var(--kt-card-cap-color);
    background-color: var(--kt-card-cap-bg);
    border-top: 1px solid var(--kt-card-border-color);
}

.px-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
}

.py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

/* .fixedbottom {
    margin: 20% 0 0 0  !important;
} */

.bh-container .card {
    border: 0;
    box-shadow: var(--kt-card-box-shadow);
    background-color: var(--kt-card-bg);
    height: 100% !important;
}

.bh-container .card .card-body {
    height: 100% !important;
    min-height: 69rem !important;
}

.bh-data-text {
    padding: 0 5px 0 0px !important;
}

/* @media print { */
/* .print-container {
        position: relative;
        min-height: 100vh;
    }

    .card-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
    } */
/* } */

.card-header {
    z-index: 999 !important;
}

.bh-align-items-end {
    align-items: flex-end !important;
}

.pl-0 {
    padding-left: 0px !important;
}

/* .bh-min-w-125px {
    min-width: 125px !important;
}

.bh-min-w-100px {
    min-width: 100px !important;
} */

.fs-order {
    font-size: 13px !important;
}

.modal-content {
    max-height: 66rem !important;
    overflow: scroll !important;
}

/* .bh-card-footer {
    position: fixed !important;
    bottom: 0 !important;
    background-color: yellow !important;
    padding: 30px !important;
    font-size: 20px !important;
} */

.item-row {
    position: relative;
}

/* .item-row::after {
    content: '';
    position: absolute;
    height: 1px;
    background-color: #000;
    width: 100%;
    left: 0;
    bottom: 0;
    opacity: .3;
} */

.bh-card-header {
    display: flex !important;
    justify-content: flex-end !important;
    align-content: center !important;
    color: #00bce1 !important;
}

.bh-card-header-p1 {
    padding: 10px 7.1rem 10px 0px !important;
}

.bh-card-header-p2 {
    padding: 10px 9.5rem 10px 0px !important;
}

.bh-cmd-info-p {
    padding-right: 8px !important;
}

.bh-lg-60px {
    height: 60px !important;
}

.bh-w-100px {
    width: 100px !important;
}

.bh-of-scale {
    object-fit: scale-down !important;
}

.min-with-th-pu {
    min-width: 107px !important;
}