.form-check-input-true:checked {
    border-color: var(--bs-success) !important;
  }
  
  .form-check-input-false {
    border-color: var(--bs-danger) !important;
    border: 1px solid var(--bs-danger) !important;
    background-image: url('../../../_metronic/assets/images/rid-circle.png') !important;
  }
  
  
  
  
  
  